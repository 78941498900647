import React from 'react'
import Icons from '../../components/icons'

export default function ScaleOverviewItem({heading, min, max, percent, current }) {
  return (
  <div className="col-12">
    <div className="scale-overview-item">
      <div className="common-box-heading cbh-small"><Icons.ScaleSvg />{heading}</div>

      <div className="soi-data-bx">
        <div className="soi-data-item">{min} min</div>
        <div className="soi-data-item">{max} max</div>
      </div>
      <div className="common-progress-bar">
        <div className="common-progress-inner" style={{ width: `${percent}%` }}>
          <div className="common-progress-circle"></div>
        </div>
      </div>
      <div className="soi-highlight">{current}</div>
    </div>
  </div>
  )
}
