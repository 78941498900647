import React from 'react';
import {Link} from "react-router-dom";


// Context Files
import { ThemeMode } from '../../context/ThemeMode';

// Icons
import Icons from '../../components/icons';

// Components
import Logo from '../../components/Logo';


export default function HeaderSignInUp({ to, onClick }) {

  // --- ---
  const { isDarkMode, toggleTheme } = ThemeMode();
  // --- ---

  return (
    <>
    <div className="signinup-screen-header">
      {(to || onClick) && 
      <Link to={to} onClick={onClick} className="SignInUpBackBtn">
        <Icons.LeftArrowSvg />
      </Link> 
      }
      <Logo className="signinup-logo-bx" />
    </div>

    <div onClick={toggleTheme} className="mode-icon-box mib-sch-btn" title={isDarkMode ? "Light mode" : "Dark mode"}>
      {isDarkMode ? <Icons.SunSvg /> : <Icons.MoonSvg />}
    </div>
    </>
  )
}
