import React from 'react'

export default function BestTodayItem({ className, rank, img, status, name, tag, time, profit, balance, openPrice, closePrice }) {
  return (
    <>
    <div className={`best-today-item bti-${rank} ${className}`}>
      <div className="bti-num">{rank}<sup>{rank === 1 ? 'st' : rank === 2 ? 'nd' : 'rd'}</sup></div>
      <div className="bti-user-detail">
        <div className='bti-img-status'>
          <img className="bti-img" src={img} alt="" />
          <div className={`bti-status ${status === "buy" ? 'buy-status' : ''} `}>{status}</div>
        </div>
        <div>
          <div className="bti-name">{name}</div>
          <div className="bti-tag">{tag}</div>
        </div>
      </div>
      <div className="bti-othet-data">
        <div className="bti-od-item">
          <div className='bti-od-i-data'>XAUUSD</div>
          <div className='bti-od-i-label'>{time}</div>
        </div>
        <div className="bti-od-item">
          <div className='bti-od-i-data text-end bti-od-i-data-green'>{profit}</div>
          <div className='bti-od-i-label text-end'>{balance}</div>
        </div>
      </div>
      {/* <div className="bti-othet-data">
        <div className="bti-od-item">
          <div className='bti-od-i-data'>{parseFloat(openPrice).toFixed(5)}</div>
          <div className='bti-od-i-label'>Open Price</div>
        </div>
        <div className="bti-od-item">
          <div className='bti-od-i-data text-end'>{parseFloat(closePrice).toFixed(5)}</div>
          <div className='bti-od-i-label text-end'>Close Price</div>
        </div>
      </div> */}
    </div>
    </>
  )
}
