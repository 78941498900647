import React from 'react'
import Icons from '../../components/icons'

export default function LimitCardItem({ heading, lcTime, leftAmount, leftText, percent, minTradingDay, maxLoss, balance, threshold, profitTarget }) {
  return (
  <div className="col-12">
    <div className="limit-card-bx">
      <div className="lc-heading">{heading}</div>
      {lcTime && <div className="lc-time"><Icons.ClockSvg />{lcTime}</div> }
      <div className="limit-card-inner">
        <div className="lci-left-amount">{leftAmount} <span>{leftText}</span></div>
        <div className="lci-percent">{percent}%</div>
        <div className="common-progress-bar">
          <div className="common-progress-inner" style={{ width: `${percent}%` }}></div>
        </div>
        <div className="lci-data-bx">
          {minTradingDay && <div>Minimum {minTradingDay} trading days</div>}
          {maxLoss && <div>Maximum {maxLoss} loss</div>}
          {balance && <div>Today's starting balance/equity: {balance}</div>}
          {threshold && <div>Threshold at: {threshold}</div>}
          {profitTarget && <div>{profitTarget}</div>}
        </div>
        <div className="lci-icon">
          <Icons.CheckCircleSvg />
        </div>
      </div>
    </div>
  </div>
  )
}
