import React from 'react'

export default function PaymentMethodItem({ src, alt, heading, onClick }) {
  return (
    <div className="col-12">
        <div className="payment-method-item" onClick={onClick}>
            <img className="pmi-img" src={src} alt={alt} />
            <div className="pmi-data-bx">
                <div className="pmi-heading">{heading}</div>
            </div>
        </div>
    </div>
  )
}
