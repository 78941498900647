import React, { useState, useRef, useEffect } from 'react';
import { NavLink ,Link} from 'react-router-dom';
// Images
import goldenWinners from '../../img/icons/golden-winners.png'
import { useAuth } from '../../context/AuthContext';
import useApi from '../../utility/apiCall';
import FlashMessage from '../../components/FlashMessage'; 
import API_URL from '../../config/config';
import * as Img from '../../components/Img';
import Loaders from '../../components/Loader';


export default function WelcomeBox() {
  const { apiCall } = useApi();
  const { state } = useAuth();
  const isInitialMount = useRef(true);
  const token = state.token || '';
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiCall(API_URL + 'get-profile', {
          user_id: state.userData.id,
          token: token,
          nopaginate: 1,
        });
        if (response.data.success == '0') {
          setFirstName(response.data.data.first_name);
          setLastName(response.data.data.last_name);
        } else {
          // console.log(response.data);
          console.error('Failed to fetch user details:', response.data.error);
        }
        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (isInitialMount.current) {
        isInitialMount.current = false;
        fetchData();
    }
  }, [token]);


  return (
    <div className="common-box cb-color-two">
        <div className="welcome-bx">
            <div className="welcome-bx-inner">
              <div className="welcome-heading">Welcome <br /> {firstName+" "+lastName}</div>

                <div className="welcome-info">
                <div className="welcome-info-heading">Are You Ready To Buy Your First Challenge?</div>
                <div className="welcome-info-text">We Recommend You Go Through The FAQ Section First Before Starting A Challenge</div>
                </div>

                <div className="d-flex justify-content-start flex-wrap cbi-gap-10px mt-4">
                <NavLink to="/new-challenge" ><div className="common-btn-item cbi-fill"><span>New Challenge</span></div>
                </NavLink>
                <Link to="https://fundedfirm.com/trading-rules" target='_blank'><div className="common-btn-item cbi-outline"><span>Trading Rules</span></div></Link>
                </div>
            </div>
            <img className="welcome-bx-img" src={goldenWinners} alt='' />
        </div>
    </div>
  )
}
