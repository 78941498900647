import React from 'react'

// Components File
import Socialicon from '../../components/Socialicon';

export default function HelpCenter() {
  return (
    <div className="common-box">
        <div className="common-box-heading">Help Center?</div>
        <div className="common-box-sub-heading" style={{maxWidth:"420px"}}>Get In Touch With Us Either Through Our Socials Or Visit The Help Center On Our Website</div>

        <Socialicon  className="social-icon mt-4" />

        <div className="d-flex flex-wrap cbi-gap-10px mt-4">
            <div className="common-btn-item cbi-fill"><span>Help Center</span></div>
        </div>
    </div>
  )
}
