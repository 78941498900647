import React from 'react';

// Icons File
import Icons from '../icons';

const SelectInput = ({ heading, className, options, selectedValue, error, errorMeg, onChange, value = null, defaultOption = null, sglIcon }) => {
  return (
    <>
        {heading && <div className="sgr-outer-label">{heading}</div>}
        <div className={`signinup-group ${className} ${error ? "error-group" : ""}`}>
            <select value={value} onChange={onChange}>
              {defaultOption && 
                 <option key={0} value={''}>
                 {defaultOption}
                 </option>
              }
            {options && options.map((option, index) => (
                <option key={index} value={option.value} disabled={option.disabled}>
                {option.name}
                </option>
            ))}
            </select>
            <div className='group_right_icon gri-select-icon'><Icons.DownSvg /></div>
            {sglIcon && <div className='group_left_icon'>{sglIcon}</div> }
            {(error && errorMeg && <span className="form-error-msg">{errorMeg}</span>)}
        </div>
    </>
  );
};

export default SelectInput;
