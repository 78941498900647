import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import useApi from '../../utility/apiCall';
import API_URL from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAuth, getLoggedInUserData } from '../../context/AuthContext';
import FlashMessage from '../../components/FlashMessage';

//import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// React Bootstrap
import { Modal } from 'react-bootstrap';

// Components File
import WelcomeBox from './WelcomeBox';
import HelpCenter from './HelpCenter';
import FaqsWidgets from './FaqsWidgets';
import ChallengeCard from './ChallengeCard';
import AccountChart from '../ComomonChart/AccountChart'; 
import EmptyBox from '../../components/EmptyBox'; 
import Loaders from '../../components/Loader';

// Icons & Images Files
import Icons from '../../components/icons';
import master from "../../img/icons/account/master.png"
import student from "../../img/icons/account/student.png"
import practitioner from "../../img/icons/account/practitioner.png"
import ChallengeNotApproved from './ChallengeNotApproved';
import RealAccountRequest from './RealAccountRequest';

import SelectInput from '../../components/commonfield/SelectInput'

import mt5exe from '../../img/mt5.exe'

export default function PageDashboard() {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const token = state.token || '';
  const isInitialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  // ---
  const [isCredentialsModal, setIsCredentialsModal] = useState(false);
  const [challengeRequests, setChallengeRequests] = useState(false);
  const [realAccountRequests, setRealAccountRequests] = useState(false);
  const [challengeRecords, setChallengeRecords] = useState(false);
  const [challenges, setChallenges] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [masterPassword, setMasterPassword] = useState('');
  const [accountNumber, setAccountNumber] = useState('');


  const [stepValue, setStepValue] = useState('');
  const [accountStatusValue, setAccountStatusValue] = useState('');
  const [typeValue, setTypeValue] = useState('');

  const [requestSubmitError, setRequestSubmitError] = useState(false);
  const [requestSubmitErrorMsg, setRequestSubmitErrorMsg] = useState(false);
  const [requestSubmitSuccess, setRequestSubmitSuccsess] = useState(false);
  const [requestSubmitSuccessMsg, setRequestSubmitSuccessMsg] = useState(false);

  const [accountDetailNumber, setAccountDetailNumber] = useState('');
  const [accountBalance, setAccountBalance] = useState('');
  const [avgWin, setAvgWin] = useState(0);
  const [avgLoss, setAvgLoss] = useState(0);
  const [winRatio, setWinRatio] = useState(0);
  const [accountType, setAccountTypeList] = useState([]);

  
  
  const [filterApply, setFilterApply] = useState(0);
  

  const handleCredentialsModalClose = () => {
      setIsCredentialsModal(false);
  };
      
  
  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  const copyClick = (value) => {
    setRequestSubmitSuccsess(false);
    setRequestSubmitError(false);

    navigator.clipboard.writeText(value)
      .then(() => {
        setRequestSubmitSuccsess(true);
        setRequestSubmitSuccessMsg('Text copied to clipboard');
        // console.log('Text copied to clipboard');
      })
      .catch((error) => {
        setRequestSubmitError(true)
        setRequestSubmitErrorMsg('Error copying text')
        // console.error('Error copying text: ', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      const loggedInUser = getLoggedInUserData();
      const isUserLoggedIn = !!loggedInUser;
      
      if (isUserLoggedIn) {
        if (loggedInUser.id && token) {
          try {
            const requests = await getChallengeRequests();
            const accountRequests = await getRealAccountRequests();
            const accountType = await getAccountTypeList();
            const records = await getChallengeRecords();
            if (activeIndex == 0 && challengeRecords && challengeRecords.length > 0) {
              const option = challengeRecords[activeIndex];
              //console.log(option.id);
              getProfitDataChart(option.id);
            }
            setDataLoaded(true)
            // console.log(records);
            // Assuming getUserWalletGroupWise returns the data needed for wallets and walletGroups
          } catch (error) {
            console.error('Failed to fetch wallet group data:', error);
          }
        }
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if (activeIndex == 0 && challengeRecords && challengeRecords.length > 0) {
      const option = challengeRecords[activeIndex];
      console.log(option.id);
      getProfitDataChart(option.id);
      getUserWalletDetails(option.id);
    }
  }, [challengeRecords]);

  useEffect(() => { 
    if (stepValue != '' || accountStatusValue != '' || typeValue != '') {
      getChallengeRequests();
      getRealAccountRequests();
      getChallengeRecords();
    }
  },[stepValue,accountStatusValue,typeValue])

  const removeSpaceWIthLowerCaseString = (inputString) => {
    return inputString.replace(/\s/g, '').toLowerCase();
  };

  const getChallengeRequests = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-challenge-requests', {
          user_id: state.userData.id,
          token: token,
          step:stepValue,
          account_status:accountStatusValue,
          type:typeValue,
          nopaginate:1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          setChallengeRequests(response.data.data);
        } else {
          setChallengeRequests(null);
        }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };

  const getRealAccountRequests= async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-real-account-requests', {
          user_id: state.userData.id,
          token: token,
          nopaginate:1
      });
      if (response.data.success == '0') {
        // console.log(response.data.data);
        if (response.data.data.length > 0) {
          console.log('realaccountrequest'+response.data.data);
          setRealAccountRequests(response.data.data);
        } else {
          setRealAccountRequests(null);
        }
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };


  const handleAccountChange = (event) => {
    // console.log(event.target.value);
    const newValue = event.target.value;
    setFilterApply(1);
    if (newValue == 'all') {
      setStepValue('all');
    } else {
      setStepValue(newValue);
    }
  }
  const handleStatusChange = (event) => {
    setFilterApply(1);
    const newValue = event.target.value;
    if (newValue == 'all') {
      setAccountStatusValue('all');
    } else {
      setAccountStatusValue(newValue);
    }
    
  }
  const handleTypeChange = (event) => {
    setFilterApply(1);
    const newValue = event.target.value;

    if (newValue == 'all') {
      setTypeValue('all');
    } else {
      setTypeValue(newValue);
    }
  }

  const handleCredentialsModalShow = async (challengeID) => {
    try {
      const response = await apiCall(API_URL +'get-challenge-details', {
          user_id: state.userData.id,
          id: challengeID,
          token: token
      });
      if (response.data.success == '0') {
        // console.log(response.data);
        setEmail(response.data.data.email);
        setAccountNumber(response.data.data.account_number);
        setMasterPassword(response.data.data.master_password);
        setIsCredentialsModal(true);
        // setChallengeDetails(response.data);
      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    // alert(challengeID);
    // Logic to get user wallet group-wise data
    
  };

  const formatStatus = (status) => {
    if (!status) return ''; // Handle case where status might be undefined or null
    const formattedStatus = status.replace(/_/g, ' '); // Replace underscores with spaces
    return formattedStatus.charAt(0).toUpperCase() + formattedStatus.slice(1); // Capitalize first letter
  };

  const getChallengeRecords = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-challenges', {
          user_id: state.userData.id,
          status: 'active',
          token: token,
          step:stepValue,
          account_status:accountStatusValue,
          type:typeValue,
          nopaginate:1
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          //console.log(response.data.data);
          setChallengeRecords(response.data.data);
        } else {
          setChallengeRecords(null);
        }

      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  
  const getAccountTypeList = async () => {
    // Logic to get user wallet group-wise data
    try {
      const response = await apiCall(API_URL +'get-account-type-list', {
          user_id: state.userData.id,
          status: 'active',
          token: token,
          step:stepValue,
          account_status:accountStatusValue,
          type:typeValue,
          nopaginate:1
      });
      if (response.data.success == '0') {
        if (response.data.data.length > 0) {
          console.log('accountType',response.data.data);
          setAccountTypeList(response.data.data);
        } else {
          setAccountTypeList([]);
        }

      }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  };
  // ---

  const accountListGet = () => {
    const manualEntry = { value: 'all', name: 'All accounts' };
    const mappedData = accountType.map(item => ({
                          value: item.step,
                          name: item.step
                        }))
    return [manualEntry, ...mappedData];

  };

  const Allaccounts =accountListGet();

  const Accountstatus = [
    { value: 'all', name: 'All'},
    // { value: 'New', name: 'New' },
    // { value: 'Ongoining', name: 'Ongoining' },
    { value: 'not_passed', name: 'Not Passed' },
    { value: 'passed', name: 'Passed' },
    // { value: 'CHF', name: 'CHF' },
  ];

  const Accounttype = [
    { value: 'all', name: 'All account' },
    { value: 'Student', name: 'Student' },
    { value: 'Practitioner', name: 'Practitioner' },
    { value: 'Master', name: 'Master' },
  ];

  const handleChange = () => {
  };

  // const getProfitDataChart = async () => {
  //   try {
  //     setIsLoading(true);
  //     // console.log({id});
  //     const response = await apiCall(API_URL +'get-trade-data-for-chart', {
  //         user_id: state.userData.id,
  //         token: token,
  //         user_wallet_id:id
  //     });
  //     if (response.data.success == '0') {
  //       console.log('trading list history', response.data.data.profitData);
  //         setProfitChartData(response.data.data.profitData);
  //         // setProfitChartData(response.data.data.data)
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //       console.error('Error fetching data:', error);
  //   }
  // };
  const [profitChartData, setProfitChartData] = useState([]);

  useEffect(() => {
    // Function to fetch profit data for a specific user_wallet_id
    
  
    // Fetch profit data when activeIndex changes
    if (activeIndex !== null && challengeRecords && challengeRecords.length > 0) {
      const option = challengeRecords[activeIndex];
      console.log(option.id);
      getProfitDataChart(option.id);
      getUserWalletDetails(option.id);
    }
  }, [activeIndex]); // Depend on activeIndex changes
  
  const getProfitDataChart = async (userWalletId) => {
    try {
      setIsLoading(true);
      const response = await apiCall(API_URL + 'get-trade-data-for-chart', {
        user_id: state.userData.id,
        token: token,
        user_wallet_id: userWalletId,
      });
      //console.log('responseresponse',response.data);
      if (response.data.success == '0') {
        // console.log(response.data.data.profitData);
        setProfitChartData(response.data.data.profitData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

    
  const getUserWalletDetails = async (userWalletId) => {
    try {
      setIsLoading(true);
      // console.log({id});
      const response = await apiCall(API_URL +'get-trade-dashboard-details', {
          user_id: state.userData.id,
          token: token,
          userWalletID:userWalletId
      });
      //console.log('responseresponse',response.data);
      if (response.data.success == '0') {
        // console.log(response.data.data.profitData);
        const dashboardDetails = response.data.data;
        setAccountDetailNumber(dashboardDetails.challengeInfo.account_number);
        setAccountBalance(dashboardDetails.challengeInfo.available_balance);
        setAvgWin(dashboardDetails.winAverage);
        setAvgLoss(dashboardDetails.lossAverage);
        setWinRatio(dashboardDetails.winRatio);
        // setAccountNumber(dashboardDetails.challengeInfo.account_number);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };


  return ( 
    <>
     {/* <div className="skeletant-bx skeletant-design sh-47"></div> */}
    <div className="custome-wrapper">

    {!dataLoaded && <Loaders/>}

    
      
      <div className="container-lg cl-custome3">  
          {/* Dashboard Section 02 Start */}
          {(((challengeRequests !== null || challengeRecords !== null) || filterApply == 1)) &&
            <div className="current-challenge-info">

              <div className="cci-left">

              <div className='row'>
                <div className="col-12">
                   
                    <div className="common-box main_filter_section  dashbord-filter d-filter">
                        {/* <div className="common-box-heading cbh-small"><Icons.HomeSvg />Home</div> */}
                        
                        <div className='row'>

                            {/* <EmptyBox className="empty-div-bx" eh="data is not found" esh="data is not found" buttonLink="/settings" buttonText="Go to home" />                           */}
                            <div className='col-sm-12 mb-1'>
                                <div className='currency-filter'>
                                    <SelectInput  className="sgl-icon sgl-opacity-1" options={Allaccounts} sglIcon=<Icons.FilterSvg />  onChange={handleAccountChange} errorMeg="Account is required" />
                                </div>
                            </div> 
                            <div className='col-sm-6 mb-2 col-6 pe-2'>
                                <div className='currency-filter'>
                                    <SelectInput className="sgl-icon sgl-opacity-1" options={Accountstatus} sglIcon=<Icons.FilterSvg />  onChange={handleStatusChange} errorMeg="Account status is required" />
                                </div>
                            </div>
                            <div className='col-sm-6 mb-2 col-6 ps-2'>
                                <div className='currency-filter'>
                                    <SelectInput className="sgl-icon sgl-opacity-1" options={Accounttype} sglIcon=<Icons.FilterSvg />  onChange={handleTypeChange} errorMeg="Account type is required" />
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>

                <Link to="/new-challenge" className="add-challenge-btn">
                  <Icons.PlusSvg/> New Challenge
                </Link>

                <div className="common-section-separator"></div>

                <div className="row row-gap-3">
                  {challengeRequests && challengeRequests.map((option, index) => (
                    <ChallengeNotApproved key={index} name={option.account_type.name} step={option.account_type.step} limit={option.account_size.limit} />
                  ))}

                  {realAccountRequests && realAccountRequests.map((option, index) => (
                    <RealAccountRequest key={index} name={option.user_wallet.name} step={option.user_wallet.account_type.step} limit={option.user_wallet.account_size.limit} />
                  ))}

                  {challengeRecords && challengeRecords.map((option, index) => (
                      // const accountType = option.account_type || 'defaultType'
                      // const step = option.account_type || 'defaultStep'
                    <ChallengeCard
                      to={"/account-detail/"+option.id}
                      className={index == activeIndex ? 'active' : ''}
                      //imgSrc={(option.account_type != undefined && option.account_type.name == 'Student') ? student : (option.account_type != undefined && option.account_type.name == 'Practitioner' ? practitioner : master)}
                      imgSrc={((option.account_status == 'real') ? practitioner : student)}
                      step={option.account_type != undefined ? option.account_type.step : ''}
                      name={"MT5 "+ option.account_number}
                      //type={option.account_type != undefined ? option.account_type.name : ''}
                      type={((option.account_status == 'real') ? 'Practitioner' : 'Student')}
                      status={formatStatus(option.account_status)}
                      statusClass={(option.account_status == 'not_passed') ? 'ccb-status-red' : (option.account_status == 'refunded' ? 'ccb-status-yellow' : (option.account_status == 'passed' ? 'ccb-status-green' : (option.account_status == 'disabled' ? 'ccb-status-yellow' : 'ccb-status-yellow')))}
                      trades={option.no_of_trades}
                      days={option.days_traded}
                      CredentialsClick={() => handleCredentialsModalShow(option.id)}
                      BoxClick={() => handleCardClick(index)} // Pass index to handleCardClick

                      />
                  ))}
                </div>

              </div>
              {(((challengeRequests !== null || challengeRecords !== null))) &&
                <div className="cci-right">
                  <div className="common-box common-sticky overflowhidden">
                
                    <div className="common-box-heading mb-0"><Icons.CircleStackSvg />Account Overview</div>
                    <div className="common-box-highlight">MT5 {accountDetailNumber}</div>

                    <AccountChart profitChartData={profitChartData} />

                    <div className="common-section-separator"></div>

                    <div className="row row-gap-3">
                      <div className="col-lg-3 col-6">
                        <div className="account-overview-item">
                          <div className="aoi-label">Account balance</div>
                          <div className="aoi-data">${accountBalance > 0 ? parseFloat(accountBalance).toFixed(2) : 0}</div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6">
                        <div className="account-overview-item">
                          <div className="aoi-label">Average win</div>
                          <div className="aoi-data aoi-status-green">${avgWin >0 ? parseFloat(avgWin).toFixed(2) :0}</div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6">
                        <div className="account-overview-item">
                          <div className="aoi-label">Average loss</div>
                          <div className="aoi-data aoi-status-red">${avgLoss > 0 ? parseFloat(avgLoss).toFixed(2) :0}</div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-6">
                        <div className="account-overview-item">
                          <div className="aoi-label">Win ratio</div>
                          <div className="aoi-data">{winRatio > 0 ? winRatio.toFixed(2) : 0}%</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              }
            </div>
          }
        {/* Dashboard Section 02 End */}
          {/* Dashboard Section 01 Start */}
          {(challengeRequests === null && challengeRecords === null && filterApply == 0) &&
            <div className="row justify-content-center row-gap-4">
              <div className="col-lg-12">
                <div className="row row-gap-4">

                  <div className="col-12">
                    <WelcomeBox />
                  </div>

                  <div className="col-12">
                    <HelpCenter />
                  </div>

                </div>
              </div>

              <div className="col-lg-4 d-none">
                <FaqsWidgets />
              </div>
            </div>
          }
        {/* Dashboard Section 01 End */}

        

      </div>
    </div>

    {/* --- Credentials Modal --- */}
    <Modal className="zoom custom-content" centered show={isCredentialsModal} onHide={handleCredentialsModalClose}>
      <div className="custom-modal-header">
          <div className="cmh-lable">Account Credentials</div>
          <div className="cmh-sub-lable">{ '#'+accountNumber}</div>
  
          <span className="close-icon" onClick={handleCredentialsModalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path>
              </svg>
          </span>
      </div>

      <div className="custom-modal-body">
          <div class="challenge-credentials-bx">
              
              <div className= {"credential-item "+ email != '' ? 'd-none':''}>
                  <div className="credential-label">Email</div>
                  <div className="credential-data">{email}<span className="credential-icon" onClick={() => copyClick(email)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Username</div>
                  <div className="credential-data">{accountNumber}<span className="credential-icon" onClick={() => copyClick(accountNumber)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Password</div>
                  <div className="credential-data">{masterPassword}<span className="credential-icon" onClick={() => copyClick(masterPassword)}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Server</div>
                  <div className="credential-data">Winprofx-Live<span className="credential-icon" onClick={() => copyClick("Winprofx-Live")}><Icons.CopySvg /></span></div>
              </div>
              <div className="credential-item">
                  <div className="credential-label">Platform</div>
                  <div className="credential-data">Metatrader 5<span className="credential-icon" onClick={() => copyClick("Metatrader 5")}><Icons.CopySvg /></span></div>
              </div>
          </div>
          <div className="mt-3 mb-4">
          <a download={true} href={mt5exe} className="common-btn-item cbi-fill mx-auto"><span>Open META TRADER 5</span></a>
          </div>
      </div>
      </Modal>
      <FlashMessage type="success" isVisible={requestSubmitSuccess} message={requestSubmitSuccessMsg} />
      <FlashMessage type="error" isVisible={requestSubmitError} message={requestSubmitErrorMsg} />
    </>
  )
}
