import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Icons File
import Icons from '../icons';

export default function FileUpload({ id, acceptedFileTypes, maxFiles, maxFileSize, onFileUpload }) {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const maxFileSizeInBytes = maxFileSize * 1024 * 1024; // Convert MB to bytes

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + selectedFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} file(s).`);
            return;
        }
        for (let file of files) {
            if (file.size > maxFileSizeInBytes) {
                alert(`File size should not exceed ${maxFileSize} MB.`);
                return;
            }
        }
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        
    };

    useEffect(() => {
        if(selectedFiles.length > 0){
            onFileUpload(selectedFiles)
        }
    }, [selectedFiles]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.target.classList.add('dragover');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.target.classList.remove('dragover');
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        if (files.length + selectedFiles.length > maxFiles) {
            alert(`You can only upload a maximum of ${maxFiles} file(s).`);
            return;
        }
        for (let file of files) {
            if (file.size > maxFileSizeInBytes) {
                alert(`File size should not exceed ${maxFileSize} MB.`);
                return;
            }
        }
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
        e.target.classList.remove('dragover');
    };

    const deleteFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    const formatFileSize = (size) => {
        const KB = 1024;
        const MB = KB * KB;
        const GB = MB * KB;
    
        if (size < KB) {
            return `${size} bytes`;
        } else if (size < MB) {
            return `${(size / KB).toFixed(2)} KB`;
        } else if (size < GB) {
            return `${(size / MB).toFixed(2)} MB`;
        } else {
            return `${(size / GB).toFixed(2)} GB`;
        }
    };

    const getFileExtension = (fileName) => {
        return fileName.split('.').pop();
    };

    return (
        <div className="row row-gap-3">
            {selectedFiles.length < maxFiles && (
                <div className="col-12">
                    <label 
                        htmlFor={id} 
                        className="fileupload" 
                        onDrop={handleDrop} 
                        onDragOver={handleDragOver}  
                        onDragLeave={handleDragLeave}  
                        onMouseOut={handleDragLeave}
                    >
                        <Icons.UploadSvg />
                        <div className="feud-lable">Drag and drop your screenshot(s) or <span>Browse</span></div>
                        <div className="feud-sub-lable">JPEG, PNG · Max {maxFiles} file(s) · {maxFileSize} MB per file</div>
                    </label>
                    <input 
                        hidden 
                        type="file"
                        id={id} 
                        accept={acceptedFileTypes}  
                        onChange={handleFileSelect} 
                    />
                </div>
            )}

            {selectedFiles.map((file, index) => (
                <div className="col-lg-12 col-12" key={index}>
                    <div className="upload-document-item">
                        <div className="udi-img-bx">
                            <img src={URL.createObjectURL(file)} alt="" />
                        </div>
                        <div className="udi-data-bx">
                            <div className="udi-data-name">{file.name}</div>
                            <div className="udi-data-type-size">{getFileExtension(file.name)}, {formatFileSize(file.size)}</div>
                        </div>
                        <div className="udi-icon-bx udi-delete" onClick={() => deleteFile(index)}>
                            <Icons.TrashSvg />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

FileUpload.propTypes = {
    id: PropTypes.string,
    acceptedFileTypes: PropTypes.string,
    maxFiles: PropTypes.number,
    maxFileSize: PropTypes.number
};

FileUpload.defaultProps = {
    id: "fileupload",
    acceptedFileTypes: "image/*",
    maxFiles: 1,
    maxFileSize: 1
};
