import React from 'react'
import PayoutRequestBtn from './PayoutRequestBtn'
import PayoutHistory from './PayoutHistory'
import { BrowserRouter as Router, Route } from 'react-router-dom';
// 
import Breadcrumb from '../../components/Breadcrumb'

export default function PagePayouts() {
  return (
    <div className="custome-wrapper">
        <Breadcrumb className="" breadcrumbIcon="EwalletsSvg" breadcrumbHeading="Payouts" />
        <div className="container-lg cl-custome3">
            <div className="row row-gap-4">
                <PayoutRequestBtn />
                <PayoutHistory />
            </div>
        </div>
    </div>
  )
}
