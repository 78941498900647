import React from 'react';
import { Link } from 'react-router-dom';

// Images Common File
import * as Img from './Img';

export default function EmptyBox(props) {
    const { className, eh, esh, buttonLink, buttonText, buttononClick, buttonClickText, img } = props;
    const imageSrc = img || Img.empty;

    return (
        <div className="col-12">
            <div className={className}>
                <img className="empty-img" src={imageSrc} alt="" />

                {eh && (
                    <div className="empty-heading">{eh}</div>
                )}

                {esh && (
                    <div className="empty-sub-heading">{esh}</div>
                )}

                {buttonLink && buttonText && (
                    <Link to={buttonLink} className="common-btn-item cbi-fill"><span>{buttonText}</span></Link>
                )}

                {buttononClick && buttonClickText && (
                    <div onClick={buttononClick} className="common-btn-item cbi-fill"><span>{buttonClickText}</span></div>
                )}
            </div>
        </div>
    );
}
