import React, { useState } from 'react';

// Icon Files
import Icons from '../icons';

export default function PasswordInput({ heading, className, placeholder, value, error, errorMeg, onChange }) {
    // View passwords js code Start
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (event) => {
        onChange(event.target.value);
    };
    // View passwords js code End
    const errorMessage = typeof errorMeg == 'string' ? errorMeg : '';
    // console.log('errorMegerrorMegerrorMegerrorMegerrorMegerrorMegerrorMegerrorMeg', errorMessage);

    return (
        <>
            {heading && <div className="sgr-outer-label">{heading}</div>}
            <div className={`signinup-group sgr-icon ${className} ${error ? "error-group" : ""}`}>
                <input type={showPassword ? "text" : "password"}  placeholder={placeholder}  value={value}  onChange={handleChange} />
                <span className={`group_right_icon ${showPassword ? "active" : ""}`} onClick={togglePasswordVisibility} >
                    {showPassword ? <Icons.ViewPassSvg /> : <Icons.HidePassSvg />}
                </span>
                {error && <span className="form-error-msg">{errorMessage}</span>}
            </div>
        </>
    );
}
