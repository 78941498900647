import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

// Context Files
import { MenuProvider } from '../context/MenuContext';
import { ThemeModeProvider } from '../context/ThemeMode';

// Common Section
import Loader from "./Loader";
import ScrollToTop from "./ScrollToTop";

//Header section
import Header from "../containers/Header/Header";

// Sign In & Up pages
import PageSignIn from "../containers/PageSignInUp/PageSignIn";
import PageSignUp from "../containers/PageSignInUp/PageSignUp";
import PageForgot from "../containers/PageSignInUp/PageForgot";
import PageReset from "../containers/PageSignInUp/PageReset";

// Home Page
import PageDashboard from "../containers/PageDashboard/PageDashboard";

// Settings Page
import PageSettings from "../containers/PageSettings/PageSettings";

// Add New Challenge page
import PageChallenge from "../containers/PageChallenge/PageChallenge";

// Account Detail page
import PageAccountDetail from "../containers/PageAccountDetail/PageAccountDetail";

// Payouts page
import PagePayouts from "../containers/PagePayouts/PagePayouts";
import PagePayoutsRequest from "../containers/PagePayouts/PagePayoutsRequest";

// Leaderboard page
import PageLeaderboard from "../containers/PageLeaderboard/PageLeaderboard";

// PageCalendar page
import PageCalendar from "../containers/PageCalendar/PageCalendar";

// PageCalendar page
import PageAffiliate from "../containers/PageAffiliate/PageAffiliate";

//  Common Message 
//import FlashMessage from "../components/FlashMessage"

export const pages = [
  // Sign In & Up pages
  { path: "/", component: PageSignIn },
  { path: "/register", component: PageSignUp },
  { path: "/forgot-password", component: PageForgot },
  { path: "/reset-password", component: PageReset },
  { path: "/reset-password/:token", component: PageReset },

  // Home page
  { path: "/home", component: PageDashboard },

  // Settings page
  { path: "/settings", component: PageSettings },

  // Add New Challenge page
  { path: "/new-challenge", component: PageChallenge },

  // Challenge Detail pages
  { path: "/account-detail/:id", component: PageAccountDetail },

  // Payouts pages
  { path: "/payouts", component: PagePayouts },
  { path: "/payouts-request", component: PagePayoutsRequest },
  
  // Leaderboard page
  { path: "/leaderboard", component: PageLeaderboard },
  
  // Calendar page
  { path: "/calendar", component: PageCalendar },

    // Affiliate page
    { path: "/affiliate", component: PageAffiliate },

];

const MyRoutes = () => {

  const location = useLocation();

  const pathsToHideheader = ['/', '/register', '/forgot-password', '/reset-password',/^\/reset-password\/[^\/]+$/];
  
  //const hideheader = pathsToHideheader.includes(location.pathname);
  const hideheader = pathsToHideheader.some(path => {
    if (path instanceof RegExp) {
      return path.test(location.pathname);
    }
    return path === location.pathname;
  });

  return (
    <>
        <ScrollToTop />
        <Loader />
        {!hideheader && <Header /> }
        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
        </Routes>
        {/* <FlashMessage /> */}
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
      <ThemeModeProvider>
        <MenuProvider>
          <MyRoutes />
        </MenuProvider>
      </ThemeModeProvider>
    </Router>
  );
}

export default MainRoutes;