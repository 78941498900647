import React from 'react'
import { Link } from 'react-router-dom';

// Images Common File
import * as Img from './Img';

export default function Logo({className}) {
  return (
    <Link to="/" className={`${className}`}>
        <img className="main-logo" src={Img.logo2} alt="" />
        <img className="main-logo2" src={Img.logo} alt="" />
    </Link>
  )
}
